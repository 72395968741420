import { Router } from '@libs/director/build/director';
import { autorun, action, runInAction} from 'mobx';
import storeFactory from '../utils/StoreFactory';


//Fetch url based on current view & module to use it for History mgmt
function getCurrentRoute() {
	const navigationStoreREF = storeFactory.NavigationStore;
	switch(navigationStoreREF.currentScreenName) {
        case navigationStoreREF.SCREEN.LOGIN : return "/app/welcome" + getLoginURL();
        case navigationStoreREF.SCREEN.HOME : return "/app/" + getHomeURL();
        case navigationStoreREF.SCREEN.RESETPASSWORD : return "/app" + getResetPasswordURL();
        case navigationStoreREF.SCREEN.CHECKOUT : return "/app" + "/checkout";
        case navigationStoreREF.SCREEN.CATALOG : return getCatalogURL();
        case navigationStoreREF.SCREEN.PRODUCT : return getProductURL();
		case navigationStoreREF.SCREEN.SUBSCRIPTION : return "/app/subscription";
        case navigationStoreREF.SCREEN.NONE : return "";
        //case navigationStoreREF.SCREEN.USER : return "/p/user/" + navigationStoreREF.getContextualParam("USER") ? navigationStoreREF.getContextualParam("USER").encoded_userId : null;
		//case navigationStoreREF.SCREEN.USERVERIFICATION : return getVerifyAccountURL();
		//case navigationStoreREF.SCREEN.SHOP : return getShopURL();
		//case navigationStoreREF.SCREEN.PAYMENT : return getPaymentStatusURL();
    }
}

function getLoginURL(){
	const navigationStoreREF = storeFactory.NavigationStore;
	console.log(" >> getLoginView : "+ navigationStoreREF.currentViewName);
	switch(navigationStoreREF.currentViewName) {
        case navigationStoreREF.VIEW.SIGNUP : return "/signUp";
        case navigationStoreREF.VIEW.FORGOTPASSWORD : return "/forgotPassword";
        case navigationStoreREF.VIEW.NONE :
		default : return (navigationStoreREF.getContextualParam("REF") ? ("?REF="+navigationStoreREF.getContextualParam("REF").redirectURLRef) : "");
    }
}

function getHomeURL(){
	const navigationStoreREF = storeFactory.NavigationStore;
	switch(navigationStoreREF.currentViewName) {
        case navigationStoreREF.VIEW.HOME : return `${storeFactory.ModuleStore.getModuleURLByName(navigationStoreREF.currentModuleName)}/page/${navigationStoreREF.activePage}`;
        case navigationStoreREF.VIEW.OPEN : return `${storeFactory.ModuleStore.getModuleURLByName(navigationStoreREF.currentModuleName)}/${navigationStoreREF.activeItem.Type}${navigationStoreREF.activeItem.Category ? "/" + navigationStoreREF.activeItem.Category + "/" : "/" }${navigationStoreREF.activeItem.uid}`;
        case navigationStoreREF.VIEW.USER : return "user";
        case navigationStoreREF.VIEW.NONE : return "";
    }
}

function getCatalogURL(){
	const navigationStoreREF = storeFactory.NavigationStore;
	switch(navigationStoreREF.currentViewName) {

		case navigationStoreREF.VIEW.CATALOG :
			const contextualParam = navigationStoreREF.getContextualParam("CATALOG");
			const mode = contextualParam ? contextualParam.mode : "PUBLIC";
			const catalogId = contextualParam ? contextualParam.catalogId : "";
			const catalogCategory = contextualParam ? contextualParam.catalogCategory : "";
			const catalogOwner = contextualParam ? contextualParam.APP_OWNER : null;
			switch(mode) {
				case "PUBLIC" : 
					return `/p/CATALOGS/${ btoa(`${catalogCategory}/${catalogId}`) }`;
				case "PROTECTED" : 
					if(catalogOwner)
						return `/pr/CATALOGS/${ btoa(`${catalogCategory}/${catalogId}/${catalogOwner}`) }`;
					else 
						return `/pr/CATALOGS/${ btoa(`${catalogCategory}/${catalogId}`) }`;
					
				default : 
					return `/p/CATALOGS/${ btoa(`${catalogCategory}/${catalogId}`) }`;
			}

		default : return "/welcome" + getLoginURL();
	}
	
}

function getProductURL(){
	const navigationStoreREF = storeFactory.NavigationStore;
	const contextualParam = navigationStoreREF.getContextualParam("PRODUCT")
	const productCategory = contextualParam ? contextualParam.productCategory : "-";
	const productId = contextualParam ? contextualParam.productId : "";
	
	const productContext = contextualParam ? contextualParam.productContext : "";
	const contextType = productContext ? productContext.contextType : "";
	const contextId = productContext ? productContext.contextId : "";

	return `/p/PRODUCTS/${ btoa(unescape(`${productCategory}/${productId}?context=${contextType}&contextId=${contextId}`)) }`;
}

function getResetPasswordURL(){
	return `/resetpassword?${ btoa(`code=${navigationStoreREF.getResetCode()}&LANGUAGE=${navigationStoreREF.currentLanguageName}`) }`;
}

function getVerifyAccountURL(){
	return `/verifyaccount?${ btoa(`code=${navigationStoreREF.getResetCode()}&LANGUAGE=${navigationStoreREF.currentLanguageName}`) }`;
}

/*function getShopURL(){
	const contextualParam = navigationStoreREF.getContextualParam("SHOP")
	let shopContextURL = "";
	if(contextualParam){
		if(contextualParam.shopCategory){
			shopContextURL = contextualParam.shopCategory;
		}
		if(contextualParam.shopID){
			shopContextURL = `${shopContextURL}/${contextualParam.shopID}`;
		}
		if(contextualParam.shopLanguage){
			shopContextURL = `${shopContextURL}/${contextualParam.shopLanguage}`;
		}		
		if(contextualParam.pageID){
			shopContextURL = `${shopContextURL}/${contextualParam.pageID}`;
		}
		if(contextualParam.itemCategory){
			shopContextURL = `${shopContextURL}/${contextualParam.itemCategory}`;
		}
		if(contextualParam.itemID){
			shopContextURL = `${shopContextURL}/${contextualParam.itemID}`;
		}
	}
	
	return `/p/WEBSHOPS/${navigationStoreREF.currentViewName}/${ btoa(unescape(shopContextURL)) }`;
}

function getPaymentStatusURL(){
	const contextualParam = navigationStoreREF.getContextualParam("Order");
	const paymentOrderID = contextualParam ? contextualParam.paymentOrderId : null;
	return `/payment/status/paymentOrderId=${paymentOrderID}`;
}*/


export class RouterStore {

	constructor(){
		this.router = new Router();
	}

	@action
	gotoWelcome(refParam){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.LOGIN);
		navigationStoreREF.changeModule("");
		navigationStoreREF.changeView(navigationStoreREF.VIEW.NONE);
		if(refParam){
			const urlParams = new URLSearchParams(refParam);
			const redirectURL = urlParams.get("REF");
			navigationStoreREF.setContextualParam("REF", {
				"redirectURLRef" : redirectURL
			});
		}
	}

	@action
	gotoWelcome_View(viewMode){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.LOGIN);
		const view = navigationStoreREF.VIEW[viewMode.toUpperCase()] ? navigationStoreREF.VIEW[viewMode.toUpperCase()] : navigationStoreREF.VIEW.NONE; 
		navigationStoreREF.changeView(view);
	}

	@action
	gotoSignUpOnInVite(encodedParam){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.LOGIN);
		navigationStoreREF.changeView(navigationStoreREF.VIEW.SIGNUP);
		const decodedParam = atob(encodedParam);
		const urlParams = new URLSearchParams(decodedParam);
		const refSourceId = urlParams.get("source");
		if(refSourceId){
			navigationStoreREF.setContextualParam("USER", {
				"refSourceId" : refSourceId
			});
		}
		const lang = urlParams.get("LANGUAGE");
		if(lang)
			navigationStoreREF.setCurrentLang(lang);
		
	}

	@action
	gotoPublicUser(encodedUserId){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.USER);
		navigationStoreREF.setContextualParam("USER", {
			"encoded_userId" : encodedUserId
		});
	}

	@action
	gotoPublicCatalog(encodedParam){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.CATALOG);
		navigationStoreREF.changeView(navigationStoreREF.VIEW.CATALOG);

		console.log("gotoPublicCatalog : encodedParam=>" + encodedParam)
		const decodedParam = atob(encodedParam);// eg:- 0x3f7b0/0x41eb1/en	
		console.log("gotoPublicCatalog : decodedParam=>" + decodedParam)
		const catalogInfo = decodedParam.split('/'); // eg:- 0x3f7b0/0x41eb1/en


		navigationStoreREF.setContextualParam("CATALOG", {
			"mode" : "PUBLIC",
			"catalogCategory" : catalogInfo[0],
			"catalogId" : catalogInfo[1]
		});

		const lang = catalogInfo[2] || "en";
		navigationStoreREF.setCurrentLang(lang);
	}

	@action
	gotoPrivateCatalog(encodedParam){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.CATALOG);
		navigationStoreREF.changeView(navigationStoreREF.VIEW.CATALOG);

		console.log("gotoPrivateCatalog : encodedParam=>" + encodedParam)
		const decodedParam = atob(encodedParam);// eg:- 0x3f7b0/0x41eb1/rajnikanth@ekayaa.co.in/en	
		console.log("gotoPrivateCatalog : decodedParam=>" + decodedParam)
		const catalogInfo = decodedParam.split('/'); // eg:- 0x3f7b0/0x41eb1/rajnikanth@ekayaa.co.in/en


		navigationStoreREF.setContextualParam("CATALOG", {
			"mode" : "PROTECTED",
			"catalogCategory" : catalogInfo[0],
			"catalogId" : catalogInfo[1],
			"APP_OWNER" : catalogInfo[2]
		});

		const lang = catalogInfo[3] || "en";
		navigationStoreREF.setCurrentLang(lang);
	}

	// eg:- /p/PRODUCTS/0x3f7b0/0x41eb1?context=CATALOG&contextId=0x41eb2
	@action
	gotoPublicProduct(encodedParam){	
		const navigationStoreREF = storeFactory.NavigationStore;	
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.PRODUCT);
		navigationStoreREF.changeView(navigationStoreREF.VIEW.NONE);
		
		console.log("encodedParam=>" + encodedParam);

		const decodedParam = unescape(atob(encodedParam));// eg:- 0x3f7b0/0x41eb1?context=CATALOG&contextId=0x41eb2	

		console.log("decodedParam=>" + decodedParam);

		const decodedProductInfo = decodedParam.split('?');
		const productInfo = decodedProductInfo[0].split('/'); // eg:- 0x3f7b0/0x41eb1
		let contextData = {
			"productId" : productInfo[1],
			"productCategory" : productInfo[0]
		}	
		const urlParams = new URLSearchParams(decodedProductInfo[1]);//eg:- context=CATALOG&contextId=0x41eb2
		const productRefContext = urlParams.get("context");
		if(productRefContext){
			const contextId =  urlParams.get("contextId");
			let productContextData = {
				contextType : productRefContext,
				contextId : contextId
			};
			contextData.productContext = productContextData;
		}
		navigationStoreREF.setContextualParam("PRODUCT", contextData);
	}

	@action
	gotoModulePage(moduleUrl, pageNo){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.HOME);
	    navigationStoreREF.resetView(moduleUrl, navigationStoreREF.VIEW.HOME, pageNo); 
	}

	@action
	gotoModuleItem(moduleUrl, type, category, itemId){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.HOME); 
		navigationStoreREF.resetView(moduleUrl, navigationStoreREF.VIEW.OPEN, 1, itemId, type, category);
	}

	@action
	gotoModuleItem_NoCategory(moduleUrl, type, itemId){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.HOME); 
	    navigationStoreREF.resetView(moduleUrl, navigationStoreREF.VIEW.OPEN, 1, itemId, type);
	}

	@action
	gotoResetPassword(encodedParam){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.RESETPASSWORD);
		navigationStoreREF.changeView(navigationStoreREF.VIEW.NONE);
		const decodedParam = atob(encodedParam);
		const urlParams = new URLSearchParams(decodedParam);
		const lang = urlParams.get("LANGUAGE");
		const resetCode =  urlParams.get("code");
		if(lang)
			navigationStoreREF.setCurrentLang(lang);
		if(resetCode)
			navigationStoreREF.setResetCode(resetCode);
	}

	/*@action
	gotoVerifyAccount(encodedParam){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.USERVERIFICATION);
		navigationStoreREF.changeView(navigationStoreREF.VIEW.NONE);
		const decodedParam = atob(encodedParam);
		const urlParams = new URLSearchParams(decodedParam);
		const lang = urlParams.get("LANGUAGE");
		const resetCode =  urlParams.get("code");
		if(lang)
			navigationStoreREF.setCurrentLang(lang);
		if(resetCode)
			navigationStoreREF.setResetCode(resetCode);
	}*/

	@action
	gotoUser(param){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.HOME); 
		//navigationStoreREF.changeView(navigationStoreREF.VIEW.USER);
		navigationStoreREF.resetView("USER", navigationStoreREF.VIEW.USER);
		const urlParams = new URLSearchParams(param);
		const reDirectedStatus = urlParams.get("status");
		if(reDirectedStatus){
			navigationStoreREF.setContextualParam("status", reDirectedStatus);
		}
	}

	@action
	gotoPayment(param){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.PAYMENT);
		const urlParams = new URLSearchParams(param);
		const paymentOrderId = urlParams.get("paymentOrderId");
		if(paymentOrderId){
			navigationStoreREF.setContextualParam("Order", {"paymentOrderId" : paymentOrderId});
		}
	}

	@action
	gotoSubscription(){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.SUBSCRIPTION);
	}

	/*@action
	gotoShop(view, encodedParam){		
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.SHOP);
		navigationStoreREF.changeView(view);
		console.log("encodedParam=>" + encodedParam);
		const decodedParam = unescape(atob(encodedParam));// eg:- 0x3f431/0x3f7b0/en/ProductPage/0x6f7b0/0x8f7b0  => {shopCategory}/{shopID}/{language}/{pageCategoryID}/{itemCategory}/{itemID}
		console.log("decodedParam=>" + decodedParam);
		const shopInfo = decodedParam.split('/');
		let contextData = {
			"shopCategory" : shopInfo[0],
			"shopID" : shopInfo[1],
			"shopLanguage" : shopInfo[2],
			"pageID" : shopInfo[3],
			"itemCategory" : shopInfo[4],
			"itemID" : shopInfo[5],
		}
		navigationStoreREF.setContextualParam("SHOP", contextData);
	}*/

	startRouter() {

	    // update state on url change
	    const routes = {
			"/app/welcome": this.gotoWelcome,
			"/app/welcome/signUp/:?encodedParam": this.gotoSignUpOnInVite,
			"/app/welcome/:viewMode": this.gotoWelcome_View,
	    	"/app/welcome/:?REF": this.gotoWelcome,
	    	"/p/user/:encodedUserId": this.gotoPublicUser,
			"/p/CATALOGS/:encodedCatalogInfo": this.gotoPublicCatalog,
			"/p/CATALOGS/:encodedCatalogInfo/:?param": this.gotoPublicCatalog,//Added for facebook crawler
			"/pr/CATALOGS/:encodedCatalogInfo": this.gotoPrivateCatalog,
	    	"/p/PRODUCTS/:?encodedURL": this.gotoPublicProduct,
	    	"/app/:module/page/:pageNo": this.gotoModulePage,
	    	"/app/:module/:type/:category/:itemId": this.gotoModuleItem,
	    	"/app/:module/:type/:itemId": this.gotoModuleItem_NoCategory,
	    	"/app/resetpassword/:?encodedParam": this.gotoResetPassword,
	    	// "/verifyaccount/:?encodedParam": this.gotoVerifyAccount,
			"/app/user/:?param": this.gotoUser,
			"/app/payment/status/:?param": this.gotoPayment,
			"/app/subscription": this.gotoSubscription,
			//"/p/WEBSHOPS/:view/:?encodedURL" : this.gotoShop
	    }
	    
		this.router.param(":\\?param", "*\\b\\?*([^\/]*)");
		this.router.param(":\\?encodedParam", "*\\?*([^\/]*)");
		this.router.param(":\\?encodedURL", "([._a-zA-Z0-9-%()&]+=*)");
		this.router.param(":encodedUserId", "([._a-zA-Z0-9-%()&]+=+)");
		this.router.param(":encodedCatalogInfo", "([._a-zA-Z0-9-%()&]+=*)");
		this.router.param(":\\?REF", "*\\b\\?*([^]*)");
	    this.router.configure({
	        notfound: () => {
				runInAction("Not Found Route", () => {
					const navigationStoreREF = storeFactory.NavigationStore;
	        		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.LOGIN);
					navigationStoreREF.changeView(navigationStoreREF.VIEW.NONE);
				}); 
	        },
	        html5history: true,
	        strict: false
	    });
	    this.router.mount(routes);
	    this.router.init();
	};

	loadURL(path){
		this.router.dispatch('on', path.charAt(0) === '/' ? path : '/' + path);
	}

	currentRouteChangeDisposer = autorun(() => {
		console.log("==== Router Store Autorun |  Route Change Activated.....");	
        const path = getCurrentRoute();
		console.log("==== Router Store Autorun | Path >> "+path);	
        if (path !== "" && path !== "/" && path !== window.location.pathname) {
        	console.log("Pushing to History : "+path);
			window.history.pushState(null, null, path);
        }
    }, {name: "currentRouteChangeDisposer"});
}

export default new RouterStore();