import React, { Component, Suspense } from 'react';
import { Button, Card, Container, Dimmer, Loader } from "semantic-ui-react";
import { ph_errorImageURL } from "./DefaultImage";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true , errorObj: error, errorInfo: info})
    console.group('componentDidCatch')
    console.error(error)
    console.info(info)
    console.groupEnd('componentDidCatch')
  }

  extra = (
    <div className="commonErrorAction">
      <Button primary>
        Report
      </Button>
    </div>
  ) 

  render() {
    if (this.state.hasError) {
      return (<Container className="commonErrorBoundary">
                    <Card.Group centered>
                      <Card
                          raised
                          image={ph_errorImageURL}
                          header='Whoops!'
                          meta='Something went wrong.'
                          description='Please report to our Support Team.'
                          extra={this.extra}
                        />
                    </Card.Group>
              </Container>)
    }
    return this.props.children
  }
}


export default Component => props => (
  <ErrorBoundary>
    <Suspense fallback={<Dimmer inverted active={true}><Loader /></Dimmer>}>
      <Component {...props} />
    </Suspense>
  </ErrorBoundary>
)