import jsonPatch from '@libs/fast-json-patch';
import _isEmpty from 'lodash/isEmpty';
import _indexOf from 'lodash/indexOf';
import { action, computed, observable, runInAction, toJS } from 'mobx';
import imageStore from '../../0-common/store/ImageStore';
import SubjectStore from '../../0-common/store/SubjectStore';
import { paymentProperties } from '../../0-common/utils/AppProperties';
import baby from '../../0-common/utils/baby';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import storeFactory from '../../0-common/utils/StoreFactory';
import VerticalFactory from '../../0-common/utils/vertical/VerticalFactory';

const SUBJECT_TYPE = 'USER';

export class UserStore extends SubjectStore {
	@observable currentReadUser = {};
	@observable currentEditUser = {};
	@observable currentUserTypeInfo = {};
	confirmPasswordValue = null;
	@observable confirmPasswordError = false;

	@observable isLoading = false;
	@observable isProfileImageLoading = false;
	@observable notifyTada = 0;

	addressStore = null;

	@observable bg_statusCheckInProgress = false;
	@observable showPaymentView = false;
	@observable checkingSubscriptionPaymentStatus = false;
	isSubscriptionPaymentDone = false;
	subscriptionPaymentOrderID = null;
	subscriptionPaymentReceiptID = null;
	subscriptionAmountToBeCharged = 0;
	subscriptionURLForPayment = '';

	constructor() {
		super();
		this.imageStore = imageStore;
		this.paymentEnvironmentType = process.env.paymentEnvironment;
	}

	getType() {
		return SUBJECT_TYPE;
	}

	@computed
	get User() {
		return this.currentReadUser;
	}

	@computed
	get UserForEdit() {
		return this.currentEditUser;
	}

	get UserTypeInfo() {
		return _isEmpty(this.currentUserTypeInfo) ? null : this.currentUserTypeInfo;
	}

	get AddressStore() {
		return this.addressStore;
	}

	set CurrentUser(currentUser) {
		this.currentReadUser = currentUser;
		this.currentEditUser = toJS(currentUser);
		this.patchObserver = jsonPatch.observe(this.currentEditUser.DATA);
		if (!this.addressStore) {
			const addressStore_X_SUBJECT_TYPE = {
				uid: currentUser.DATA.uid,
				Type: 'ADDRESS',
				Category: '',
				FIND_WITHIN: true,
				PAGE_SIZE: -1,
				FIND_WITH_FILTERS: true,
				REQUESTED_PAGE: 1,
			};
			this.addressStore = storeFactory.fetchStore('APPADDRESS', addressStore_X_SUBJECT_TYPE);
		}
		storeFactory.CurrencyStore.registerCurrency(currentUser);
	}

	set CurrentUserTypeInfo(currentUserTypeInfo) {
		this.currentUserTypeInfo = currentUserTypeInfo;
	}

	get ActiveSubscriptionStatus() {
		if (this.currentReadUser && this.currentReadUser.DATA) {
			const activeSubscriptionId = this.currentReadUser.DATA.ActiveSubscription;
			const activeSubscriptionRef = this.currentReadUser.REFERENCES[activeSubscriptionId];
			if (activeSubscriptionRef) {
				let activeSubscriptionStatus = activeSubscriptionRef.SubscriptionStatus;
				if (activeSubscriptionRef.Name === 'Free') {
					activeSubscriptionStatus = 'Free';
				}
				return activeSubscriptionStatus;
			} else {
				return 'UNKNOWN';
			}
		} else {
			return 'UNKNOWN';
		}
	}

	get PendingSubscriptionStatus() {
		if (this.currentReadUser && this.currentReadUser.DATA) {
			const pendingSubscription = this.currentReadUser.DATA.PendingForActivationSubscription;
			if (pendingSubscription) {
				const pendingSubscriptionId = pendingSubscription[0];
				const pendingSubscriptionRef = this.currentReadUser.REFERENCES[pendingSubscriptionId];
				if (pendingSubscriptionRef) {
					const pendingSubscriptionStatus = pendingSubscriptionRef.SubscriptionStatus;
					return pendingSubscriptionStatus;
				} else {
					return 'UNKNOWN';
				}
			} else {
				return 'UNKNOWN';
			}
		} else {
			return 'UNKNOWN';
		}
	}

	get isSubscriptionValid() {
		return this.SubscriptionStore.isUserSubscriptionActive;
	}

	setConfirmPassword(cPassword) {
		this.confirmPasswordValue = cPassword;
	}

	@action
	reset() {
		this.currentReadUser = {};
		this.currentEditUser = {};
		this.currentUserTypeInfo = {};
		this.confirmPasswordValue = null;
		this.confirmPasswordError = false;
		this.SubscriptionStore.clearRepository();
	}

	@action
	resetSubscriptionPaymentStatus() {
		this.bg_statusCheckInProgress = false;
		this.showPaymentView = false;
		this.checkingSubscriptionPaymentStatus = false;
		this.isSubscriptionPaymentDone = false;
		this.subscriptionPaymentOrderID = null;
		this.subscriptionPaymentReceiptID = null;
		this.subscriptionAmountToBeCharged = 0;
		this.subscriptionURLForPayment = '';
	}

	/*async setCurrentUser(userId){
		try{
			//const itemInfo = {"Type":SUBJECT_TYPE, "uid":userId, "Category" : ""};
			const response = await this.getUserService();
			runInAction("On Get Current User",() => {		
				if(response.SUBJECT) {
					this.currentReadUser = response.SUBJECT;
					this.currentEditUser = toJS(response.SUBJECT);
					this.patchObserver = jsonPatch.observe(this.currentEditUser.DATA);
					response.onComplete();
				}
			});	
		}
		catch (error){
		    throw error;
		}
	}*/

	/*async loadCurrentUser(){
		try{
			this.isLoading = true;
			if(_isEmpty(this.User))
			{
				const pair = document.cookie.match(new RegExp("LOGIN" + '=([^;]+)'));
				if(!!pair) {
					await this.setCurrentUser(pair[1]);
				} else {
					console.error("User cookie not found.");
					const userParams = storeFactory.NavigationStore.getContextualParam("USER");
					if(userParams && userParams.encoded_userId){
						const decoded_UserId = window.atob(userParams.encoded_userId);
						await this.setPublicUser(decoded_UserId);
					} else {
						console.error("User Contextual Param not found.");
					}
					
				}
			}
		}
		catch (error){
		    storeFactory.ErrorStore.log(error, "Couldn't load user", "On load Current User Error");
		}
		finally {
			this.isLoading = false;	
		}
	}*/

	async loadPublicUser(userId) {
		try {
			const userInfo = { Type: SUBJECT_TYPE, uid: userId, Category: '' };
			const response = await this.getUserById_PublicAccess(userInfo);
			runInAction('On Get Current User', () => {
				if (response.SUBJECT) {
					this.CurrentUser = response.SUBJECT;
					this.CurrentUserTypeInfo = response.SUBJECT_TYPE;
				}
			});
		} catch (error) {
			throw error;
		}
	}

	async fetchCurrentSessionUser() {
		try {
			//const itemInfo = {"Type":SUBJECT_TYPE, "uid":userId, "Category" : ""};
			const response = await this.getUserBySession();
			runInAction('On Fetch Current User', () => {
				if (response.SUBJECT) {
					this.CurrentUser = response.SUBJECT;
					this.CurrentUserTypeInfo = response.SUBJECT_TYPE;
				}
				response.onComplete();
				return this.User;
			});
		} catch (error) {
			throw error;
		}
	}

	async loadCurrentUser(force = false) {
		try {
			this.isLoading = true;
			if (force || _isEmpty(this.User)) {
				const userParams = storeFactory.NavigationStore.getContextualParam('USER');
				if (userParams && userParams.encoded_userId) {
					const decoded_UserId = window.atob(userParams.encoded_userId);
					await this.loadPublicUser(decoded_UserId);
				} else {
					await this.fetchCurrentSessionUser();
				}
			}
		} catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't load user", 'On load Current User Error');
		} finally {
			this.isLoading = false;
		}
	}

	@action
	updateProperty(propertyName, propertyValue) {
		this.currentEditUser.DATA[propertyName] = propertyValue;
		console.log(this.currentEditUser.DATA);
	}

	async addProfileImage(imageName, sourceID, imagePatchData) {
		try {
			this.isProfileImageLoading = true;
			const saveResponse = await this.imageStore.createUserProfileImage(imageName, sourceID, imagePatchData);
			runInAction('On Add Image', () => {
				this.isProfileImageLoading = true;
				this.currentEditUser.DATA['ProfileImage'] = saveResponse.DATA.uid;
				this.saveUserProfileChanges();
			});
		} catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't add image", 'On Add Image Error');
		} finally {
			emitter.emit(EVENTS.PREDICATE.ADDIMAGE.DONE);
			this.isProfileImageLoading = false;
		}
	}

	checkConfirmPassword() {
		try {
			this.isLoading = true;
			if (this.confirmPasswordValue) {
				if (this.confirmPasswordValue !== this.currentEditUser.DATA.SECRET) {
					throw 'Your password confirmation did not match your password.';
				} else {
					this.confirmPasswordError = false;
				}
			}
			return true;
		} catch (error) {
			storeFactory.ErrorStore.log(error, error, 'On Check confirm password Error');
			this.confirmPasswordError = true;
			return false;
		} finally {
			this.isLoading = false;
		}
	}

	async saveUserProfileChanges() {
		try {
			this.isLoading = true;
			const patch = jsonPatch.generate_new(this.patchObserver);
			if (patch.length > 0) {
				const itemInfo = { Type: SUBJECT_TYPE, uid: this.currentEditUser.DATA.uid, Category: '' };
				const response = await this.saveUser(itemInfo, patch);
				runInAction('ON Save User Profile', () => {
					this.isLoading = true;
					this.CurrentUser = response.SUBJECT;
					response.onComplete();
				});
			}
		} catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't save", 'On Save User Profile Error');
		} finally {
			this.isLoading = false;
		}
	}

	cancelUserProfileChanges() {
		try {
			this.isLoading = true;
			this.currentEditUser = toJS(this.currentReadUser);
			this.patchObserver = jsonPatch.observe(this.currentEditUser.DATA);
			this.confirmPasswordValue = null;
			this.confirmPasswordError = false;
		} catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't revert user changes", 'ON Cancel User Profile Changes');
		} finally {
			this.isLoading = false;
		}
	}

	/*********** Subscription API *****************/

	async initiatePaymentForSubscription(mode, plan, cycle) {
		try {
			this.isLoading = true;
			this.showPaymentView = false;
			this.isSubscriptionPaymentDone = false;
			this.subscriptionPaymentOrderID = null;
			this.subscriptionPaymentReceiptID = null;
			this.subscriptionAmountToBeCharged = 0;
			const param = {
				SubscriptionMode: mode,
				SubscriptionPlan: plan,
				SubscriptionCycle: cycle,
			};
			const response = await this.initiatePaymentForSubscriptionService(param);
			runInAction('ON createPaymentForSubscription', () => {
				this.subscriptionPaymentOrderID = response.SubscriptionID;
				this.subscriptionPaymentReceiptID = response.PaymentUID;
				this.subscriptionAmountToBeCharged = response.SubscriptionAmountToBeCharged;
				this.showPaymentView = true;
				response.onComplete();
			});
		} catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't initiatePaymentForSubscription", 'On initiatePaymentForSubscription Error');
		} finally {
			this.isLoading = false;
		}
	}

	@action
	upgradeSubscription(plan, cycle) {
		try {
			//this.isLoading = true;
			const param = {
				SubscriptionPlan: plan,
				SubscriptionCycle: cycle,
				SubscriptionID: this.subscriptionPaymentOrderID,
				PaymentReceiptID: this.subscriptionPaymentReceiptID,
				SubscriptionAmountToBeCharged: this.subscriptionAmountToBeCharged,
			};
			this.upgradeSubscriptionService(param);
		} catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't upgrade Subscription", 'On upgradeSubscription Error');
		} finally {
			//this.isLoading = false;
		}
	}

	@action
	async downgradeSubscription(plan, cycle) {
		try {
			//this.isLoading = true;
			const param = {
				SubscriptionPlan: plan,
				SubscriptionCycle: cycle,
				SubscriptionID: this.subscriptionPaymentOrderID,
				PaymentReceiptID: this.subscriptionPaymentReceiptID,
				SubscriptionAmountToBeCharged: this.subscriptionAmountToBeCharged,
			};
			this.downgradeSubscriptionService(param);
		} catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't downgrade Subscription", 'On downgradeSubscription Error');
		} finally {
			//this.isLoading = false;
		}
	}

	@action
	async renewSubscription(plan, cycle) {
		try {
			//this.isLoading = true;
			const param = {
				SubscriptionPlan: plan,
				SubscriptionCycle: cycle,
				SubscriptionID: this.subscriptionPaymentOrderID,
				PaymentReceiptID: this.subscriptionPaymentReceiptID,
				SubscriptionAmountToBeCharged: this.subscriptionAmountToBeCharged,
			};
			this.renewSubscriptionService(param);
		} catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't Renew Subscription", 'On renewSubscription Error');
		} finally {
			//this.isLoading = false;
		}
	}

	async cancelSubscription() {
		try {
			this.isLoading = true;
			await this.cancelSubscriptionService();
			this.loadCurrentUser(true);
		} catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't Cancel Subscription", 'On Cancel Subscription ERROR');
		} finally {
			this.isLoading = false;
		}
	}

	async paySubscription(subscriptionAmount) {
		const vertical = process.env.vertical;
		const verticalConfig = VerticalFactory.fetchVertical(vertical);
		const logoInfo = verticalConfig.getLogoConfig();

		try {
			this.isLoading = true;
			this.isSubscriptionPaymentDone = false;
			var options = {
				key: paymentProperties.PAYMENT_ENVIRONMENT_KEY[this.paymentEnvironmentType], // Enter the Key ID generated from the Dashboard
				amount: subscriptionAmount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
				currency: 'INR',
				name: 'Tridasha',
				description: 'Standard Plan',
				image: logoInfo.profileImage_mini,
				//"subscription_id": this.subscriptionIdForPayment, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
				order_id: this.subscriptionPaymentOrderID, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
				handler: this.callback_processSubscriptionPayment,
				prefill: {
					name: this.User.DATA.Name,
					email: this.User.DATA.Email,
					contact: this.User.DATA.MobileNumber,
				},
				notes: {},
				theme: {
					color: '#2897ea',
				},
			};
			var rzp1 = new Razorpay(options);
			rzp1.on('payment.failed', (response) => {
				console.log(response.error.code);
				console.log(response.error.description);
				console.log(response.error.source);
				console.log(response.error.step);
				console.log(response.error.reason);
				console.log(response.error.metadata.order_id);
				console.log(response.error.metadata.payment_id);
			});
			rzp1.open();
		} catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't execute paySubscription ", 'On Execute paySubscription ');
		} finally {
			runInAction('pay Subscription', () => {
				this.showPaymentView = false;
				this.isLoading = false;
			});
		}
	}

	@action
	callback_processSubscriptionPayment = async (razorPayResponse) => {
		try {
			this.isSubscriptionPaymentDone = true;
			this.checkingSubscriptionPaymentStatus = true;
			this.showPaymentView = true;
			//const SubscriptionID = razorPayResponse.razorpay_subscription_id;
			const SubscriptionID = razorPayResponse.razorpay_order_id;
			const razorpay_payment_id = razorPayResponse.razorpay_payment_id;
			const razorpay_signature = razorPayResponse.razorpay_signature;
			const subscriptionPaymentResponse = { SubscriptionID, razorpay_payment_id, razorpay_signature };

			const response = await this.processSubscriptionPaymentService(subscriptionPaymentResponse);
			runInAction('Process Subscription Payment Response', () => {
				this.checkingSubscriptionPaymentStatus = false;
				this.CurrentUser = response.SUBJECT;
				storeFactory.NavigationStore.resetView('USER', storeFactory.NavigationStore.VIEW.USER);
				response.onComplete();
				this.bg_StatusCheckPoller();
			});
		} catch (error) {
			storeFactory.ErrorStore.log(
				error,
				"Couldn't execute callback_processSubscriptionPayment ",
				'On Execute callback_processSubscriptionPayment'
			);
		} finally {
			this.checkingSubscriptionPaymentStatus = false;
		}
	};

	bg_StatusCheckPoller() {
		console.log('START  < bg_StatusCheckPoller >  START');
		let counter = 0;
		let doContinue = true;
		this.bg_statusCheckInProgress = true;
		let setIntervalHandler = setInterval(async () => {
			counter++;
			console.log(counter + '. Calling bg_CheckingPendingSubscriptionStatus times : ' + counter);
			doContinue = await this.bg_CheckingPendingSubscriptionStatus();
			if (counter === 6 || !doContinue) {
				this.bg_statusCheckInProgress = false;
				clearInterval(setIntervalHandler);
				console.log('END  < bg_StatusCheckPoller >  END');
			}
		}, 15000);
	}

	async bg_CheckingPendingSubscriptionStatus() {
		let isPending = false;
		try {
			console.log('1. bg_CheckingPendingSubscriptionStatus > loadCurrentUser');
			await this.loadCurrentUser(true);
			const pendingSubscription = this.User.DATA.PendingForActivationSubscription;
			if (pendingSubscription) {
				console.log('2. bg_CheckingPendingSubscriptionStatus > pendingSubscription found');
				const pendingSubscriptionKey = pendingSubscription[0];
				if (pendingSubscriptionKey) {
					const pendingSubscriptionInfo = this.User.REFERENCES[pendingSubscriptionKey];
					if (_indexOf(['PendingActivation', 'PendingRenewal'], pendingSubscriptionInfo['SubscriptionStatus']) !== -1) {
						console.log('3. bg_CheckingPendingSubscriptionStatus > pending STATUS found');
						isPending = true;
					}
				}
			}
		} catch (error) {
			console.error('bg_CheckingPendingSubscriptionStatus ERROR : ' + error);
			console.log(error);
		} finally {
			console.log('Finally bg_CheckingPendingSubscriptionStatus > pending STATUS : ' + isPending);
			return isPending;
		}
	}

	//============================== Service Layer ===============================//

	async getUserBySession() {
		try {
			const response = await baby.get('/getuser');
			return this.processSubscriptionInfoFromResponse(response.data);
		} catch (error) {
			console.error('getUser Failed with Error : ' + error);
			throw error;
		}
	}

	async getUserById(itemInfo) {
		try {
			const response = await baby.post('/getuser', itemInfo);
			return this.processSubscriptionInfoFromResponse(response.data);
		} catch (error) {
			console.error('getUser Failed with Error : ' + error);
			throw error;
		}
	}
	async getUserById_PublicAccess(userInfo) {
		try {
			const response = await baby.post('/publicread', userInfo);
			return response.data;
		} catch (error) {
			console.error('getUser Failed with Error : ' + error);
			throw error;
		}
	}

	async saveUser(itemInfo, userJsonPatch) {
		try {
			const response = await baby.post('/updateuser', {
				Type: itemInfo.Type,
				uid: itemInfo.uid,
				Category: itemInfo.Category,
				PATCH: userJsonPatch,
			});
			return this.processResponseDataForCreateUpdateRead(response.data);
		} catch (error) {
			console.error('saveUser Failed with Error : ' + error);
			throw error;
		}
	}

	async markAddressAsDefaultService(itemInfo, defaultAddressUserJsonPatch) {
		try {
			const response = await baby.post('/setaddressasdefault', {
				Type: itemInfo.Type,
				uid: itemInfo.uid,
				Category: itemInfo.Category,
				PATCH: defaultAddressUserJsonPatch,
			});
			return this.processResponseDataForCreateUpdateRead(response.data);
		} catch (error) {
			console.error('addNewAddressService Failed with Error : ' + error);
			throw error;
		}
	}

	async updateEmail(newEmail) {
		try {
			const response = await baby.post('/updateemail', { SourceID: newEmail });
			return this.processResponseDataForCreateUpdateRead(response.data);
		} catch (error) {
			console.error('addNewAddressService Failed with Error : ' + error);
			throw error;
		}
	}

	async initiatePaymentForSubscriptionService(subscriptionParam) {
		try {
			const response = await baby.post('/initiatepaymentforsubscription', subscriptionParam);
			return response.data;
		} catch (error) {
			console.error('initiatePaymentForSubscriptionService Failed with Error : ' + error);
			throw error;
		}
	}

	async upgradeSubscriptionService(subscriptionParam) {
		try {
			const response = await baby.post('/upgradesubscription', subscriptionParam);
			return this.processSubscriptionInfoFromResponse(response.data);
		} catch (error) {
			console.error('upgradeSubscriptionService Failed with Error : ' + error);
			throw error;
		}
	}

	async downgradeSubscriptionService(subscriptionParam) {
		try {
			const response = await baby.post('/downgradesubscription', subscriptionParam);
			return this.processSubscriptionInfoFromResponse(response.data);
		} catch (error) {
			console.error('downgradeSubscriptionService Failed with Error : ' + error);
			throw error;
		}
	}

	async renewSubscriptionService(subscriptionParam) {
		try {
			const response = await baby.post('/renewsubscription', subscriptionParam);
			return this.processSubscriptionInfoFromResponse(response.data);
		} catch (error) {
			console.error('renewSubscriptionService Failed with Error : ' + error);
			throw error;
		}
	}

	async cancelSubscriptionService() {
		try {
			const response = await baby.get('/cancelsubscription');
			return response.data;
		} catch (error) {
			console.error('cancelSubscriptionService Failed with Error : ' + error);
			throw error;
		}
	}

	async processSubscriptionPaymentService(subscriptionPaymentResponse) {
		try {
			const response = await baby.post('/processsubscriptionpayment', subscriptionPaymentResponse);
			return this.processResponseDataForCreateUpdateRead(response.data);
		} catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't execute processSubscriptionPaymentService ", 'On processSubscriptionPaymentService Error');
			throw error;
		}
	}
}

export default new UserStore();
