import _forEach from 'lodash/forEach';
import { action, computed, observable } from 'mobx';
import React from 'react';
import authStore from '../../0-common/store/AuthStore';
import routerStore from '../../0-common/store/RouterStore';
import storeFactory from '../../0-common/utils/StoreFactory';
import userStore from './UserStore';

class LoginStore {

	constructor(){
		this.init();
	}

	init(){
		//	Login Form
		this.username = React.createRef();
	    this.password = React.createRef();
	    //	Regiter Form
		this.name = React.createRef();
	    this.rusername = React.createRef();
	    this.rpassword = React.createRef();
	    this.mobilenumber = React.createRef();
	    //	Forgot password Form
	    this.fusername = React.createRef();
	    //	Reset Password Form
	    this.nPassword = React.createRef();
		this.cPassword = React.createRef();
		// Business Form
		this.businessName = React.createRef();
		this.businessCurrency = React.createRef();
		this.businessCategory = React.createRef();
	}

	@observable isLoading = false;
	@observable userRegistrationActiveStep = 1;
	@observable userForgotPasswordActiveStep = 1;
	@observable resetChangePasswordForm = false;
	registeredUserEmailID = "";
	forgotPasswordUserEmailID = "";

	@observable loginValidStatus = {
		username : {
			empty : false,
			invalid : false,
			msg : ""
		},
		password : {
			empty : false,
			invalid : false,// never used, added for data model consistency.
			msg : ""
		}
	}

	@computed 
	get isUsernameInValid(){
		return (this.loginValidStatus.username.empty || this.loginValidStatus.username.invalid)
	}

	@computed 
	get usernameErrorMsg(){
		return this.loginValidStatus.username.msg;
	}

	@computed 
	get isPasswordEmpty(){
		return (this.loginValidStatus.password.empty)
	}

	@computed 
	get passwordErrorMsg(){
		return this.loginValidStatus.password.msg;
	}

	@observable registerValidStatus = {
		name : {
			empty : false,
			invalid : false,
			msg : ""
		},
		rusername : {
			empty : false,
			invalid : false,
			msg : ""
		},
		rpassword : {
			empty : false,
			invalid : false,
			msg : ""
		},
		mobilenumber : {
			empty : false,
			invalid : false,
			msg : ""
		}
	}

	@computed
	get isRegisterNameInValid(){
		return (this.registerValidStatus.name.empty);
	}

	@computed
	get registerNameErrorMsg(){
		return this.registerValidStatus.name.msg;
	}

	@computed
	get isRegisterEmailInValid(){
		return (this.registerValidStatus.rusername.empty || this.registerValidStatus.rusername.invalid);
	}

	@computed
	get registerEmailErrorMsg(){
		return this.registerValidStatus.rusername.msg;
	}

	@computed
	get isRegisterPasswordInValid(){
		return (this.registerValidStatus.rpassword.empty || this.registerValidStatus.rpassword.invalid);
	}

	@computed
	get registerPasswordErrorMsg(){
		return this.registerValidStatus.rpassword.msg;
	}

	@computed
	get isRegisterMobilenumberInValid(){
		return (this.registerValidStatus.mobilenumber.empty || this.registerValidStatus.mobilenumber.invalid);
	}

	@computed
	get registerMobilenumberErrorMsg(){
		return this.registerValidStatus.mobilenumber.msg;
	}

	@observable verifyAccountValidStatus = {
		code : {
			empty : false,
			invalid : false,
			msg : ""
		},
		codeResend : {
			error : false,
			success : false,
			msg : "" 
		},
		updateEmail : {
			empty : false,
			invalid : false,
			error : false,
			success : false,
			msg : "" 
		}
	}

	@computed
	get isVerificationCodeInValid(){
		return (this.verifyAccountValidStatus.code.empty || this.verifyAccountValidStatus.code.invalid);
	}

	@computed
	get verificationCodeErrorMsg(){
		return this.verifyAccountValidStatus.code.msg;
	}

	@computed
	get isResendCodeFailed(){
		return (this.verifyAccountValidStatus.codeResend.error);
	}

	@computed
	get isResendCodeSuccess(){
		return (this.verifyAccountValidStatus.codeResend.success);
	}

	@computed
	get resendCodeStatusMsg(){
		return this.verifyAccountValidStatus.codeResend.msg;
	}

	@computed
	get isUpdateEmailInValid(){
		return (this.verifyAccountValidStatus.updateEmail.empty || this.verifyAccountValidStatus.updateEmail.invalid);
	}

	@computed
	get isUpdateEmailSuccess(){
		return (this.verifyAccountValidStatus.updateEmail.success);
	}

	@computed
	get updateEmailErrorMsg(){
		return this.verifyAccountValidStatus.updateEmail.msg;
	}

	@computed
	get updateEmailStatusMsg(){
		return this.verifyAccountValidStatus.updateEmail.msg;
	}

	@observable forgotPswdValidStatus = {
		fusername : {
			empty : false,
			invalid : false,
			msg : ""
		}
	}

	@computed
	get isforgotPswdEmailEmpty(){
		return (this.forgotPswdValidStatus.fusername.empty);
	}

	@computed
	get forgotPswdEmailErrorMsg(){
		return this.forgotPswdValidStatus.fusername.msg;
	}

	@observable changePswdValidStatus = {
		nPassword : {
			empty : false,
			invalid : false,
			mismatch : false,
			msg : ""
		},
		cPassword : {
			empty : false,
			invalid : false,
			mismatch : false,
			msg : ""
		}
	}

	@computed
	get isChangePswdInValid(){
		return (this.changePswdValidStatus.nPassword.empty || this.changePswdValidStatus.nPassword.invalid);
	}

	get isChangePswdConfirmInValid(){
		return (this.changePswdValidStatus.cPassword.empty || this.changePswdValidStatus.cPassword.mismatch);
	}

	@computed
	get changePswdErrorMsg(){
		return this.changePswdValidStatus.nPassword.msg;
	}

	@computed
	get changePswdConfirmErrorMsg(){
		return this.changePswdValidStatus.cPassword.msg;
	}

	scanStorage(){
		const isVisited = JSON.parse(localStorage.getItem('IC_tracker'));
		const isRedirected = storeFactory.NavigationStore.getContextualParam("REF");
		if(!isRedirected){
			if(!isVisited){
				if(storeFactory.NavigationStore.currentViewName === storeFactory.NavigationStore.VIEW.NONE)
				storeFactory.NavigationStore.changeView(storeFactory.NavigationStore.VIEW.SIGNUP);
			}
		}
	}

	ref = (fieldName) => {
		return this[fieldName];
	}

	get = (fieldName) => {
		const fieldRef = this[fieldName].current ? this[fieldName].current.inputRef.current : null;
		const value = fieldRef ? fieldRef.value : "";
		return value;
	}	

	reset = (fieldName) => {
		const fieldRef = this[fieldName].current ? this[fieldName].current.inputRef.current : null;
		if(fieldRef){
			fieldRef.value = "";
		}
	}

	get RegisteredEmail(){
		return this.registeredUserEmailID;
	}

	get ForgotPasswordEmail(){
		return this.forgotPasswordUserEmailID;
	}
	
	@action.bound
	gotoLoginScreen(){
		this.resetLoginForm();
		this.resetLoginStatus();
    	storeFactory.NavigationStore.changeScreen(storeFactory.NavigationStore.SCREEN.LOGIN);
    	storeFactory.NavigationStore.changeView(storeFactory.NavigationStore.VIEW.NONE);
	}

	@action.bound
	gotoLoginView(){
		this.resetLoginForm();
		this.resetLoginStatus();
    	storeFactory.NavigationStore.changeView(storeFactory.NavigationStore.VIEW.NONE);
	}

	@action.bound
	gotoRegisterView(){
		this.resetRegisterForm();
		this.resetRegisterStatus();

		this.resetVerifyAccountStatus();

		this.userRegistrationActiveStep = 1;

    	storeFactory.NavigationStore.changeScreen(storeFactory.NavigationStore.SCREEN.LOGIN);
    	storeFactory.NavigationStore.changeView(storeFactory.NavigationStore.VIEW.SIGNUP);
	}

	@action.bound
	gotoForgotPasswordView(){
		this.resetForgotPSWDForm();
		this.resetForgotPSWDStatus();

		this.resetVerifyAccountStatus();

		this.resetChangePSWDForm();
		this.resetChangePSWDStatus();

		this.userForgotPasswordActiveStep = 1;

    	storeFactory.NavigationStore.changeView(storeFactory.NavigationStore.VIEW.FORGOTPASSWORD);
	}
	
	@action
	resetLoginStatus = (fieldName) => {
		if(fieldName){
			this.loginValidStatus[fieldName] = {
				empty : false,
				invalid : false,
				msg : ""
			}
		} else {
			this.loginValidStatus = {
				username : {
					empty : false,
					invalid : false,
					msg : ""
				},
				password : {
					empty : false,
					invalid : false,
					msg : ""
				}
			}
		}
	}

	@action.bound
	resetRegisterStatus(fieldName){
		if(fieldName){
			this.registerValidStatus[fieldName] = {
				empty : false,
				invalid : false,
				msg : ""
			}
		} else {
			this.registerValidStatus = {
				name : {
					empty : false,
					invalid : false,
					msg : ""
				},
				rusername : {
					empty : false,
					invalid : false,
					msg : ""
				},
				rpassword : {
					empty : false,
					invalid : false,
					msg : ""
				},
				mobilenumber : {
					empty : false,
					invalid : false,
					msg : ""
				}
			}
		}
	}

	@action.bound
	resetVerifyAccountStatus(){
		this.verifyAccountValidStatus = {
			code : {
				empty : false,
				invalid : false,
				msg : ""
			},
			codeResend : {
				error : false,
				success : false,
				msg : "" 
			},
			updateEmail : {
				empty : false,
				invalid : false,
				error : false,
				success : false,
				msg : "" 
			}
		}
	}
	
	@action.bound
	resetForgotPSWDStatus(){
		this.forgotPswdValidStatus = {
			fusername : {
				empty : false,
				invalid : false,
				msg : ""
			}
		}
	}

	@action.bound
	resetChangePSWDStatus(){
		this.changePswdValidStatus = {
			nPassword : {
				empty : false,
				invalid : false,
				mismatch : false,
				msg : ""
			},
			cPassword : {
				empty : false,
				invalid : false,
				mismatch : false,
				msg : ""
			}
		}
	}

	resetLoginForm(){
	    this.reset("username");
	    this.reset("password");
	}

	resetRegisterForm(){
		this.reset("name");
		this.reset("rusername");
		this.reset("rpassword");
		this.reset("mobilenumber");
	}

	resetForgotPSWDForm(){
		this.reset("fusername");
	}

	resetChangePSWDForm(){
	    this.reset("nPassword");
	    this.reset("cPassword");
	}

	get isURLAllowedWithoutSubscription(){
		if(storeFactory.NavigationStore.getContextualParam("REF")){
			const path = storeFactory.NavigationStore.getContextualParam("REF").redirectURLRef;
			const isCatalogURL = path.includes("/pr/CATALOGS") || path.includes("/p/CATALOGS");
			if(isCatalogURL){
				return true;
			}
		}
		return false;
	}

	/******************************************  LOGIN API ******************************************/

	@action.bound
	async executeLogin(){
		try {
			this.isLoading = true;
			this.resetLoginStatus();
			const username = this.get("username");
			const password = this.get("password");
			console.log("U :"+username+"  #. P : "+password);

			const isLoginFormValid = this.validateLoginForm();
			if(!isLoginFormValid){
				this.isLoading = false;
				return;
			}

			const response = await authStore.login(username, password, storeFactory.NavigationStore.currentLanguageName);
			console.log(" Login Status : "+response.status);
			if(response.status) 
				this.onLoginSuccess(response.user);
			else
				this.onLoginFailure(response.error);
		}
		catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't login", "on executeLogin Error");
			this.onLoginFailure(error);
		}
		finally {
			this.isLoading = false;	
		}
	}

	validateLoginForm(){
		let isLoginFormValid = true;
		const username = this.get("username");
		const password = this.get("password");

		const emailCheckRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const isEmailValid = emailCheckRegex.test(username);
		if(username === ""){
			this.loginValidStatus.username.empty = true;
			this.loginValidStatus.username.msg = "Please enter your email address."
			isLoginFormValid = false;
		} else if(!isEmailValid){
			this.loginValidStatus.username.invalid = true;
			this.loginValidStatus.username.msg = "Please enter a valid email address."
			isLoginFormValid = false;
		} else {
			this.loginValidStatus.username.empty = false;
			this.loginValidStatus.username.invalid = false;
		}

		if(password === ""){
			this.loginValidStatus.password.empty = true;
			this.loginValidStatus.password.msg = "Please enter your password."
			isLoginFormValid = false;
		} else {
			this.loginValidStatus.password.empty = false;
		}

		return isLoginFormValid
	}

	@action.bound
	onLoginSuccess(user){
		userStore.CurrentUser = user.SUBJECT;
		userStore.CurrentUserTypeInfo = user.SUBJECT_TYPE;
		userStore.updateSubscriptionRepository(user.SUBSCRIPTION_INFO, user.USER_CATEGORY);
		//Subscription Validation Check 
		if(userStore.isSubscriptionValid){
			if(storeFactory.NavigationStore.getContextualParam("REF")){
				const path = storeFactory.NavigationStore.getContextualParam("REF").redirectURLRef;
				routerStore.loadURL(path);
			} else {
				storeFactory.NavigationStore.changeScreen(storeFactory.NavigationStore.SCREEN.HOME);
			}
		} else {//Redirect to User Profile Screen BUT if the REF param exist & the url in REF is for public/protected catalog url, then allow.
			if(this.isURLAllowedWithoutSubscription){
				const path = storeFactory.NavigationStore.getContextualParam("REF").redirectURLRef;
				routerStore.loadURL(path);
			} else {
				storeFactory.NavigationStore.changeScreen(storeFactory.NavigationStore.SCREEN.LOGIN);
    			storeFactory.NavigationStore.changeView(storeFactory.NavigationStore.VIEW.NONE);
			}
		}		
		//storeFactory.NavigationStore.changeView(storeFactory.NavigationStore.VIEW.LIST);
		this.isLoading = false;
		localStorage.setItem('IC_tracker', JSON.stringify(true));
		this.resetLoginForm();
		this.resetLoginStatus();
	}

	@action.bound 
	onLoginFailure(errorData){
		this.isLoading = false;
		if(errorData.EXCEPTION){
			if(errorData.EXCEPTION.ClassName === "AuthenticationFailedException"){
				this.loginValidStatus.username.invalid = true;
				this.loginValidStatus.username.msg = "Your email or password is incorrect";
			}
			if(errorData.EXCEPTION.ClassName === "NoSuchUserException"){
				this.loginValidStatus.username.invalid = true;
				this.loginValidStatus.username.msg = "Your email is not registered with us";
			}
		}
	}


	/****************************************************************   REGISTER API   ****************************************************************/

	@action.bound
	async executeRegister(){
		try{
			this.isLoading = true;
			this.resetRegisterStatus();
			const name = this.get("name");
			const username = this.get("rusername");
			const password = this.get("rpassword");
			const mobilenumber = this.get("mobilenumber");

			const isRegisterFormValid = this.validateUserRegistrationForm();
			if( !isRegisterFormValid ){
				this.isLoading = false;
				return;
			}

			let response = null;
			const contextualParam = storeFactory.NavigationStore.getContextualParam("USER");
			if(contextualParam && contextualParam.refSourceId){
				response = await authStore.registerForInvite(name, username, password, mobilenumber, contextualParam.refSourceId, storeFactory.NavigationStore.currentLanguageName);
			} else {
				response = await authStore.register(name, username, password, mobilenumber, storeFactory.NavigationStore.currentLanguageName);
			}
			
			console.log(" Register Status : "+response.status);
			if(response.status)
				this.onRegisterSuccess(response.user);
			else
				this.onRegisterFailure(response.error);
		}
		catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't Register", "on executeRegister Error");
			this.onRegisterFailure(error);
		}
		finally {
			this.isLoading = false;	
		}
	}

	validateUserRegistrationForm(){

		let isRegisterFormValid = true;
		
		const name = this.get("name");
		const username = this.get("rusername");
		const password = this.get("rpassword");
		const mobilenumber = this.get("mobilenumber");
		
		//Name Field validation
		if(name === ""){
			this.registerValidStatus.name.empty = true;
			this.registerValidStatus.name.msg = "Please enter your name."
			isRegisterFormValid = false;
		} else {
			this.registerValidStatus.name.empty = false;
		}

		//Email Field validation
		const emailCheckRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const isEmailValid = emailCheckRegex.test(username);
		if(username === ""){
			this.registerValidStatus.rusername.empty = true;
			this.registerValidStatus.rusername.msg = "Please enter your email address."
			isRegisterFormValid = false;
		} else if(!isEmailValid){
			this.registerValidStatus.rusername.invalid = true;
			this.registerValidStatus.rusername.msg = "Please enter a valid email address."
			isRegisterFormValid = false;
		} else {
			this.registerValidStatus.rusername.empty = false;
			this.registerValidStatus.rusername.invalid = false;
		}

		//Password Field validation
		if(password === ""){
			this.registerValidStatus.rpassword.empty = true;
			this.registerValidStatus.rpassword.msg = "Password cannot be empty."
			isRegisterFormValid = false;
		} else if(password.length < 8){
			this.registerValidStatus.rpassword.invalid = true;
			this.registerValidStatus.rpassword.msg = "Password must be atleast 8 characters."
			isRegisterFormValid = false;
		} else {
			this.registerValidStatus.rpassword.empty = false;
			this.registerValidStatus.rpassword.invalid = false;
		}

		//MobileNumber Field validation
		const mobileNumberCheckRegex = /^\d{10}$/;
		const isMobileNumberValid = mobileNumberCheckRegex.test(mobilenumber);
		if(mobilenumber === ""){
			this.registerValidStatus.mobilenumber.empty = true;
			this.registerValidStatus.mobilenumber.msg = "Please enter your mobile number."
			isRegisterFormValid = false;
		} else if(!isMobileNumberValid){
			this.registerValidStatus.mobilenumber.invalid = true;
			this.registerValidStatus.mobilenumber.msg = "Mobile number must be valid and contain 10 digits."
			isRegisterFormValid = false;
		} else {
			this.registerValidStatus.mobilenumber.empty = false;
			this.registerValidStatus.mobilenumber.invalid = false;
		}

		return isRegisterFormValid;
	}


	@action.bound
	onRegisterFailure(errorData){
		this.isLoading = false;
		if(errorData.EXCEPTION){
			if(errorData.EXCEPTION.ClassName === "UserAlreadyExistsException"){
				this.registerValidStatus.rusername.invalid = true;
				this.registerValidStatus.rusername.msg = errorData.EXCEPTION.Message;
			}
		}
	}

	@action.bound
	onRegisterSuccess(user){
		userStore.CurrentUser = user.SUBJECT;
		userStore.CurrentUserTypeInfo = user.SUBJECT_TYPE;
		this.registeredUserEmailID = user.SUBJECT.DATA.Email;
		this.isLoading = false;
		localStorage.setItem('IC_tracker', JSON.stringify(true));
		this.resetRegisterStatus();
		this.userRegistrationActiveStep++;
	}

	/*********************************************************   VERIFY ACCOUNT API   ********************************************************/

	@action.bound
	async executeVerifyUserAccount(verificationCode, context){
		try{
			this.isLoading = true;
			let emailID = "";
			switch (context){
				case 'RESET_PASSWORD' :
					emailID = this.forgotPasswordUserEmailID;
					break;

				case 'USER_REGISTRATION':
					emailID = this.registeredUserEmailID;
					break;
			}
			if(verificationCode === ""){
				this.verifyAccountValidStatus.code.empty = true;
				this.verifyAccountValidStatus.code.msg = "Please enter the verification code.";
				this.isLoading = false;
				return;
			}

			const response = await authStore.verifyUserAccount(emailID, verificationCode, context, storeFactory.NavigationStore.currentLanguageName);
			console.log("Verify Account Status : "+response.status);
			if(response.status){
				this.onVerifyUserAccountSuccess(response.user);
			} else {
				this.onVerifyUserAccountError(response.error);
			}
		}
		catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't verify account", "on executeChangePassword Error");
			this.onVerifyUserAccountError();
		}
		finally {
			this.isLoading = false;
		}
	}

	@action.bound
	onVerifyUserAccountError(errorData){
		console.error("Error in executeVerifyUserAccount ");
		console.error(errorData);
		this.isLoading = false;
		this.resetVerifyAccountStatus();
		this.verifyAccountValidStatus.code.invalid = true;
		this.verifyAccountValidStatus.code.msg = "The code you have entered is not valid.";
		//storeFactory.ErrorStore.log(value('lb_uv_error'), value('lb_uv_error'), "execute Verify User Account");
	}

	@action.bound
	onVerifyUserAccountSuccess(user){
		this.isLoading = false;
		userStore.CurrentUser = user.SUBJECT;
		userStore.CurrentUserTypeInfo = user.SUBJECT_TYPE;
		localStorage.setItem('IC_tracker', JSON.stringify(true));
		this.resetVerifyAccountStatus();
		this.userRegistrationActiveStep++;
		this.userForgotPasswordActiveStep++;
	}

	/*********************************************************   RESEND OTP API   ********************************************************/

	@action.bound
	async executeResendCode(context){
		try{
			this.isLoading = true;
			let emailID = "";
			switch (context){
				case 'RESET_PASSWORD' :
					emailID = this.forgotPasswordUserEmailID;
					break;

				case 'USER_REGISTRATION':
					emailID = this.registeredUserEmailID;
					break;
			}
			await authStore.resendCode(emailID, context);
		}
		catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't resend code", "on executeResendCode Error");
		}
		finally {
			this.onResendCodeDone();
		}
	}

	@action.bound
	onResendCodeDone(){
		this.isLoading = false;
		this.resetVerifyAccountStatus();
		this.verifyAccountValidStatus.codeResend.success = true;
		this.verifyAccountValidStatus.codeResend.msg = "New verification code sent successfully."
	}

	/****************************************************   UPDATE EMAIL FOR OTP - API   **************************************************/

	@action.bound
	async executeUpdateEmail(){
		try{
			this.isLoading = true;
			const username = this.get("rusername");
			//Email Field validation
			const emailCheckRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			const isEmailValid = emailCheckRegex.test(username);
			if(username === ""){
				this.resetVerifyAccountStatus();
				this.verifyAccountValidStatus.updateEmail.empty = true;
				this.verifyAccountValidStatus.updateEmail.msg = "Please enter your email address."
				this.isLoading = false;
				return false;
			} else if(!isEmailValid){
				this.resetVerifyAccountStatus();
				this.verifyAccountValidStatus.updateEmail.invalid = true;
				this.verifyAccountValidStatus.updateEmail.msg = "Please enter a valid email address."
				this.isLoading = false;
				return false;
			} else {
				this.resetVerifyAccountStatus();
				this.verifyAccountValidStatus.updateEmail.empty = false;
				this.verifyAccountValidStatus.updateEmail.invalid = false;
			}
			
			const response = await userStore.updateEmail(username);
			this.onUpdateEmailSuccess(response.SUBJECT);
			return true;
		}
		catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't update email", "on executeUpdateEmail Error");
			const errorData = error.response.data;
			this.onUpdateEmailError(errorData);
			return false;
		}
		finally {
			this.isLoading = false;
		}
	}

	@action.bound
	onUpdateEmailError(errorData){
		this.isLoading = false;
		this.resetVerifyAccountStatus();
		if(errorData.EXCEPTION){
			if(errorData.EXCEPTION.ClassName === "UserAlreadyExistsException"){
				this.verifyAccountValidStatus.updateEmail.error = true;
				this.verifyAccountValidStatus.updateEmail.msg = errorData.EXCEPTION.Message;
				return;
			}
		}
		this.verifyAccountValidStatus.updateEmail.error = true;
		this.verifyAccountValidStatus.updateEmail.msg = "Please try again."
		//storeFactory.ErrorStore.log(value('lb_uv_error'), value('lb_uv_error'), "execute Verify User Account");
	}

	@action.bound
	onUpdateEmailSuccess(user){
		this.isLoading = false;
		userStore.CurrentUser = user;
		this.registeredUserEmailID = user.DATA.Email;
		this.resetVerifyAccountStatus();
		this.verifyAccountValidStatus.updateEmail.success = true;
		this.verifyAccountValidStatus.updateEmail.msg = "Email updated successfully."
	}

	/******************************************************   SAVE BUSINESS INFO API   ***************************************************/

	@action.bound
	async executeSaveBusinessInfo(){
		try{
			this.isLoading = true;
			const businessName = this.get("businessName");
			const businessCurrency = this["businessCurrency"].current.state.value;
			const businessCategory = this["businessCategory"].current.state.value;
			const userInfo = {
				"Type": "USER", 
				"uid": userStore.User.DATA.uid,
				"Category" : ""
			};	
			const patch = [
				{"op":"add", "path": "/businessname", "value": businessName},
				{"op":"add", "path": "/Currency", "value": businessCurrency}
			];
			_forEach(businessCategory, (category) => {
				patch.push(
					{"op":"add", "path": "/Verticals", "value": category}
				);
			})
			await userStore.saveUser(userInfo, patch);
			this.userRegistrationActiveStep++;
		}
		catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't update email", "on executeUpdateEmail Error");
		}
		finally {
			this.isLoading = false;
		}
	}

	/*********************************************************   FORGOT PASSWORD API   ********************************************************/

	@action.bound
	async executeForgotPassword(){
		try{
			this.isLoading = true;
			const username = this.get("fusername");
			console.log("U :"+username);
			
			//Email Field validation
			if(username === ""){
				this.forgotPswdValidStatus.fusername.empty = true;
				this.forgotPswdValidStatus.fusername.msg = "Please enter your email address."
				this.isLoading = false;
				return false;
			} else {
				this.forgotPswdValidStatus.fusername.empty = false;
				this.forgotPswdValidStatus.fusername.invalid = false;
			}

			const status = await authStore.forgotPassword(username, storeFactory.NavigationStore.currentLanguageName);
			console.log(" Forgot Pass Status : "+status);
			this.forgotPasswordUserEmailID = username;
			this.onForgotPasswordDone(status);
		}
		catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't execute forgot password", "on executeForgotPassword Error");
		}
		finally {
			this.isLoading = false;	
		}
	}

	@action.bound
	onForgotPasswordDone(status){
		this.resetForgotPSWDStatus();
		this.isLoading = false;
		localStorage.setItem('IC_tracker', JSON.stringify(true));
		this.userForgotPasswordActiveStep++;
	}

	/*********************************************************   RESET PASSWORD API   ********************************************************/

	@action.bound
	async executeChangePassword(){
		try{
			this.isLoading = true;
			const username = this.forgotPasswordUserEmailID;
			const newPassword = this.get("nPassword");
			const cpassword = this.get("cPassword");

			//Password Field validation
			if(newPassword === ""){
				this.changePswdValidStatus.nPassword.empty = true;
				this.changePswdValidStatus.nPassword.msg = "Password cannot be empty."
				this.isLoading = false;
				return false;
			} else if(newPassword.length < 8){
				this.changePswdValidStatus.nPassword.invalid = true;
				this.changePswdValidStatus.nPassword.msg = "Password must be atleast 8 characters."
				this.isLoading = false;
				return false;
			} else {
				this.changePswdValidStatus.nPassword.empty = false;
				this.changePswdValidStatus.nPassword.invalid = false;
			}

			if(cpassword === ""){
				this.changePswdValidStatus.cPassword.empty = true;
				this.changePswdValidStatus.cPassword.msg = "Password cannot be empty."
				this.isLoading = false;
				return false;
			}
			
			if(newPassword === cpassword) {
				const status = await authStore.resetPassword(username, newPassword, storeFactory.NavigationStore.currentLanguageName);
				console.log(" Reset Password Status : "+status);
				this.onChangePasswordDone();
			} else {
				this.onChangePasswordMismatch();
			}
		}
		catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't change password", "on executeChangePassword Error");
		}
		finally {
			this.isLoading = false;	
		}
	}

	@action.bound
	onChangePasswordMismatch(){
		this.isLoading = false;
		this.resetChangePSWDStatus();
		this.changePswdValidStatus.cPassword.mismatch = true;
		this.changePswdValidStatus.cPassword.msg = "Please make sure your passwords match.";
		//this.changePswdValidStatus.cPassword.msg = value('lb_rp_error_passwordmismatch');
	}

	@action.bound
	onChangePasswordDone(){
		this.resetChangePSWDForm();
		this.resetChangePSWDStatus();
		this.isLoading = false;
		localStorage.setItem('IC_tracker', JSON.stringify(true));
		this.userForgotPasswordActiveStep++;
	}

	/******************************************************   CATALOG LOGIN API   ********************************************************/

	@action.bound
	async executeLoginForCatalog(){
		try {
			this.isLoading = true;
			this.resetLoginStatus();
			const username = this.get("username");
			const password = this.get("password");
			console.log("U :"+username+"  #. P : "+password);
			if(username === ""){
				this.loginValidStatus.username = true;
				this.isLoading = false;
				return;
			} else {
				this.loginValidStatus.username = false;
			}
			const response = await authStore.login(username, password, storeFactory.NavigationStore.currentLanguageName);
			console.log(" Catalog Login Status : "+response.status);
			if(response.status) 
				this.onCatalogLoginSuccess(response.user);
			else
				this.onCatalogLoginFailure();
		}
		catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't login for catalog ", "on executeCatalogLogin Error");
			this.onCatalogLoginFailure();
		}
		finally {
			this.isLoading = false;	
		}
	}
	
	@action.bound
	onCatalogLoginSuccess(user){
		userStore.CurrentUser = user.SUBJECT;
		userStore.CurrentUserTypeInfo = user.SUBJECT_TYPE;
		storeFactory.NavigationStore.changeView(storeFactory.NavigationStore.VIEW.NONE);
		this.isLoading = false;
		localStorage.setItem('IC_tracker', JSON.stringify(true));
	}

	@action.bound 
	onCatalogLoginFailure(){
		this.resetLoginForm();
		this.isLoading = false;
	}

	/***************************************** LOGOUT API  ***************************************** */

	@action.bound
	async executeLogout(){
		try{
			this.isLoading = true;	
			const status = await authStore.logout();
			console.log(" Login Status : "+status);
			if(status) 
				this.onLogoutSuccess();
			else
				this.onLogoutFailure();
		}
		catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't logout", "on executeLogout Error");
			this.onLogoutFailure();
		}
		finally {
			this.isLoading = false;	
		}
	}

	@action.bound
	onLogoutSuccess(){
		storeFactory.NavigationStore.changeModule("");
		storeFactory.NavigationStore.changeScreen(storeFactory.NavigationStore.SCREEN.LOGIN);
		storeFactory.NavigationStore.changeView(storeFactory.NavigationStore.VIEW.NONE);
		this.resetLoginForm();
		userStore.reset();
		storeFactory.resetStore();
		this.isLoading = false;
	}

	@action.bound
	onLogoutFailure(){
		storeFactory.NavigationStore.changeModule("");
		storeFactory.NavigationStore.changeScreen(storeFactory.NavigationStore.SCREEN.LOGIN);
		storeFactory.NavigationStore.changeView(storeFactory.NavigationStore.VIEW.NONE);
		this.resetLoginForm();
		userStore.reset();
		storeFactory.resetStore();
		this.isLoading = false;
	}


	/***************************************** CHANGE LANGUAGE API  ***************************************** */

	@action.bound
	executeChangeLanguage(lang){
		storeFactory.NavigationStore.setCurrentLang(lang);
		//Autorun attached to language change in languageStore
	}

	
	/******************************************  Catalog LOGIN API ******************************************/

	@action.bound
	async executeCatalogLogin(catalogOwner){
		try {
			this.isLoading = true;
			this.resetLoginStatus();
			const username = this.get("username");
			const password = this.get("password");
			console.log("U :"+username+"  #. P : "+password);

			const isLoginFormValid = this.validateLoginForm();
			if(!isLoginFormValid){
				this.isLoading = false;
				return;
			}

			const response = await authStore.catalogLogin(username, password, storeFactory.NavigationStore.currentLanguageName, catalogOwner);
			console.log(" Login Status : "+response.status);
			if(response.status) 
				this.onLoginSuccess(response.user);
			else
				this.onLoginFailure(response.error);
		}
		catch (error) {
			storeFactory.ErrorStore.log(error, "Couldn't login in catalog", "on executeCatalogLogin Error");
			this.onLoginFailure(error);
		}
		finally {
			this.isLoading = false;	
		}
	}
}

export default new LoginStore(); 